html, body {
  height: 100%;
  font-size: 12px;
  color: $font-color-t1;
  font-family: "im-roboto", Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
  overflow: hidden;
  background: $primary-bg-color;
}

* {
  box-sizing: border-box;
  word-break: break-all;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0;
  font-kerning: none;
  padding: 0;
  line-height: 1.2;
}

*:focus {
  outline: none;
}

#root {
  height: 100%;
  overflow: hidden;
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  color: $font-color-t4;
}

input:-moz-placeholder, textarea:-moz-placeholder {
  color: $font-color-t4;
}

input::-moz-placeholder, textarea::-moz-placeholder {
  color: $font-color-t4;
}

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  color: $font-color-t4;
}

.price {
  font-family: DIN, im-roboto, Inter, serif;
}

.my-avatar-group {
  display: flex;
  align-items: center;

  .avatar-group-user {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    color: $font-color-t3;
    //gap: 4px;
    font-size: 12px;

    .avatar-image {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 1px solid #fff;

      &:not(:last-child) {
        margin-left: -6px;
      }
    }
    >span {
      margin-left: 4px;
    }
  }

  .avatar-group-num {
    margin-left: 4px;
    color: #5F6975;
    font-weight: 700;
  }
}

.my-loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.my-switch {
  display: inline-block;
  width: 50px;
  height: 28px;
}

.my-numberInput {
  width: 100%;
  display: flex;
  height: 28px;
  justify-content: space-between;
  align-items: center;
  background: #E6E9F4;
  border-radius: 28px;

  .btn {
    width: 28px;
    height: 28px;
    flex-shrink: 0;
    border-radius: 28px;
    background: linear-gradient(180deg, #F0F3FF 0%, #DDE0E9 100%);
    box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.05) inset;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .input {
    font-size: 13px;
    padding: 0 8px;
  }
}

.my-floatModal {
  .my-floatModal-panel {
    --border-radius: 30px;
    //--header-height: 24px;
    .adm-floating-panel-header {
      .adm-floating-panel-bar {
        width: 33px;
        height: 4px;
        background: $font-color-t3;
        border-radius: 17px;
      }
    }
  }

  .floatModal-header {
    padding: 0 16px;
    height: 58px;
    display: flex;
    align-items: center;
    position: relative;

    .close {
      position: absolute;
      width: 24px;
      height: 24px;
      display: flex;
    }

    .title {
      color: $font-color-t1;
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      margin: 0 auto;
    }
  }

  .floatModal-content {
    padding: 0 8px;
    flex: 1;
    overflow-y: auto;
  }

  .floatModal-handler {
    padding: 12px 16px;
    box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.10);
    background: $modal-handler-bg;
    //20272B
    height: 64px;
    z-index: 999;
    width: 100%;
  }
}

.my-count-time {
  display: flex;
  gap: 2px;
  font-weight: 500;
  color: #fff;

  .time {
    background: #000;
    border-radius: 4px;
    font-family: DIN;
    font-weight: 700;
    font-size: 9px;
    padding: 2px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
  }

}

.component-toast {
  padding: 24px;
  border-radius: 12px;
  background: rgba(0, 0, 0, 0.70);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  left: 50%;
  top: 50%;
  min-width: 280px;
  transform: translate(-50%, -50%);
  z-index: 1000;

  .icon {
    width: 28px;
    height: 28px;
  }

  .text {
    margin-top: 8px;
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
  }
}

.voice-wave ::part(cursor) {
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background: $font-color-t3;
  top: 50%;
  margin-top: -6px;
}
.voice-wave-play ::part(cursor) {
  background: $base-color;
}
.circle-loading {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;

  .circle-loading-bg {
    width: 156px;
    height: 132px;
    border-radius: 12px;
    background: $primary-bg-color;
    display: flex;
    justify-content: center;
    align-items: center;

    .circle-loading-bg-logo {
      width: 52px;
      height: 52px;
      animation: circle 1s linear infinite;
    }
  }

  @keyframes circle {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
}

.selectBar {
  position: fixed;
  padding: 0 8px;
  color: $font-color-t1;
  font-weight: 500;
  //height: 52px;

  font-size: 20px;
  top: 0;
  z-index: 1000;
  width: 100%;
  background: $home-selectBar-bg;
  .selectBar-content {
    height: 44px;
    display: flex;
    align-items: center;
    .selectBar-left {
      display: flex;
      align-items: center;
      column-gap: 12px;

      .navbar-backIcon {
        width: 24px;
        height: 24px;
      }
    }

    .selectBar-right {
      margin-left: auto;
      display: flex;
      align-items: center;
      gap: 16px;

      .icon {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.common-placeholder {
  background: #ccc;
  max-height: 400px;
}

.custom-scroll {
    scrollbar-width: 0;
    scrollbar-color: rgba(0,0,0,0) rgba(0,0,0,0);
    -webkit-overflow-scrolling: touch;
    pointer-events: auto;
}
.clearfix {
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}
