@import "@/styles/variables";
.followedSelf, .followedOther {
  margin-top: 8px;
  display: flex;
  align-items: center;
}

.followedSelf {
  .followedLeft {
    color: $font-color-t3;
    font-size: 12px;
    font-weight: 500;
    flex: 1;
  }
}

.followedOther {
  justify-content: space-between;

  .followedButton {
    display: inline-flex;
    color: $button-icon;
    font-weight: 700;
    height: 28px;
    font-size: 12px;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: $base-color;

    &:active {
      filter: brightness(0.8);
    }
  }
}

