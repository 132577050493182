@import "../../styles/mixins";
@import "../../styles/variables";
@import "../../styles/function";
@import "../../styles/common";
@import "../../styles/animation";
@import "../../styles/modal";
@import "../../styles/antd-moblie";
@import "../../styles/map";

.common-setting-modal {
  padding: 20px;
  font-size: 14px;
  background: $card;
  border-radius: 20px;

  .title {
    color: $font-color-t2;
    font-family: "im-roboto";
    font-size: 16px;
    font-weight: 700;
  }

  .content {
    margin-top: 12px;
    font-weight: 400;
    color: $font-color-t3;
    font-size: 14px;
  }

  .handler {
    display: flex;
    justify-content: end;
    margin-top: 16px;

    .btn {
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      color: $primary-text-color;

      &:active {
        background: rgba(0, 168, 132, 0.1);
        border-radius: 51px;
      }
    }
  }

}
