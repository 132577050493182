.qc-online-state-online {
  width: fit-content;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  padding: 2px 4px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  background: rgba(4, 195, 0, 0.08);
  color: #04c300;
  font-family: Inter;
  font-size: 10px;
  font-weight: 700;
}
.qc-online-state-online:before {
  content: "";
  width: 4px;
  height: 4px;
  border-radius: 4px;
  background: #04c300;
}
.qc-online-state-offline {
  width: fit-content;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  padding: 2px 4px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  background: rgba(242, 66, 66, 0.08);
  color: #f24242;
  font-family: Inter;
  font-size: 10px;
  font-weight: 700;
}
.qc-online-state-offline:before {
  content: "";
  width: 4px;
  height: 4px;
  border-radius: 4px;
  background: #f24242;
}
.qc-online-state-busy {
  width: fit-content;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  padding: 2px 4px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  background: rgba(247, 133, 29, 0.08);
  color: #f7851d;
  font-family: Inter;
  font-size: 10px;
  font-weight: 700;
}
.qc-online-state-busy:before {
  content: "";
  width: 4px;
  height: 4px;
  border-radius: 4px;
  background: #f7851d;
}
.qc-online-state-away {
  width: fit-content;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  padding: 2px 4px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  background: rgba(230, 193, 0, 0.08);
  color: #e6c100;
  font-family: Inter;
  font-size: 10px;
  font-weight: 700;
}
.qc-online-state-away:before {
  content: "";
  width: 4px;
  height: 4px;
  border-radius: 4px;
  background: #e6c100;
}
.reflex-container .reflex {
  gap: 2px;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
}
.reflex-container .reflex .reflex-item {
  width: 24px;
  height: 24px;
  line-height: 24px;
  align-items: center;
  justify-content: center;
  display: inline-flex;
}
.reflex-container .satta {
  font-size: 12px;
  display: flex;
  align-items: center;
}
.reflex-container .satta .satta-item {
  font-weight: 500;
  margin-right: 4px;
}
.reflex-container .satta .value {
  font-weight: 700;
}
.reflex-container .digit {
  display: flex;
  align-items: center;
  gap: 4px;
}
.reflex-container .digit .digit-item {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  font-size: 12px;
  font-weight: 700;
}
.reflex-container .comprehensive {
  gap: 4px;
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
}
.reflex-container .comprehensive .comprehensive-item {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  line-height: 20px;
}
.reflex-container .comprehensive .matrix-item {
  font-size: 12px;
  padding: 0 4px;
  height: 20px;
  display: inline-flex;
  line-height: 20px;
  justify-content: center;
  border-radius: 4px;
}
.hm_waterfall-loading {
  height: 32px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  transition: all 0.15s;
  margin-top: 10px;
}
.hm_waterfall-container {
  box-sizing: border-box;
  padding: 20px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.hm_container {
  position: relative;
  width: 100%;
}
.hm_waterfall-item {
  position: absolute;
  transition: opacity 0.12s;
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
  background-color: #ccc;
  border-radius: 10px;
  opacity: 0;
  overflow: hidden;
}
.hm_waterfall-item-main {
  flex-grow: 1;
  flex-shrink: 0;
}
