@import "~@/styles/variables.scss";
.rechargeModal {
  .input__content {
    height: 48px;
    padding: 8px 12px;
    background: var(--recharge-input-bg);
    border: 1px solid var(--recharge-input-bg);
    border-radius: 4px;
    display: flex;
    gap: 4px;
    flex-direction: column;
    justify-content: center;

    .input {
      --color: var(--whats-color-t2);
      font-size: 14px;
      min-height: 17px;
      font-weight: 800;
    }

    .extra {
      color: #F15802;
      font-size: 10px;
      font-weight: 700;
      //font-family: Inter;
    }
  }

  .tips {
    margin-top: 8px;
    color: $font-color-t3;
    font-size: 14px;
    font-weight: 500;

    .tips__base {
      color: $base-color;
    }
  }

  .limit {
    margin-top: 8px;
    display: flex;
    gap: 16px;

    .limit__item {
      --gap: 4px;

      .limit__item__label {
        color: $font-color-t3;
        font-weight: 500;
      }

      .limit__item__value {
        color: $font-color-t2;
        font-weight: 700;
      }
    }
  }

  .recharge__list {
    margin-top: 8px;
    padding: 10px 0;
    --gap-horizontal: 8px;
    --gap-vertical: 16px;

    .recharge__item {
      height: 40px;
      border-radius: 4px;
      border: 1px solid var(--recharge-card-border);
      background: var(--recharge-card-bg);
      box-shadow: var(--recharge-card-shadow);
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $font-color-t2;
      font-size: 14px;
      font-weight: 700;
      position: relative;

      .badge {
        position: absolute;
        background: #DC083B;
        border-radius: 8px 12px 12px 0;
        padding: 2px 6px;
        font-size: 10px;
        font-weight: 600;
        top: -10px;
        left: 50%;
        white-space: nowrap;
        transform: translateX(-50%);
        pointer-events: none;
        color: #fff29c;
        animation: recharge-tip-zoom 1s ease-in-out infinite;

        &:after {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          border-top: 4px solid #DC083B;
          border-right: 4px solid transparent;
          top: 100%;
          left: 0;
        }
      }
    }

    .recharge__item__active {
      background: $base-color;
      box-shadow: none;
      color: #fff;
    }
  }
}

.handler {
  border-radius: 20px;
  padding: 12px 20px;
  background: $base-color;
  color: $button-icon;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 700;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25), 0px 1px 0px 0px rgba(255, 255, 255, 0.20) inset;
  &:active {
    filter: brightness(0.94);
  }
}

@keyframes recharge-tip-zoom {
  0% {
    transform: scale(1) translateX(-50%);
  }
  50% {
    transform: scale(.95) translateX(-50%);
  }
  100% {
    transform: scale(1) translateX(-50%);
  }
}
