// 单行省略
@mixin ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 去除滚动条样式
@mixin removeScrollBar {
  &::-webkit-scrollbar {
    display: none;
    width: 0;
  }
}


