@font-face {
    font-family: "im-roboto";
    src: url("ROBOTO/Roboto-Medium-12.ttf");
    font-weight: 500;
}

@font-face {
    font-family: "im-roboto";
    src: url("ROBOTO/Roboto-Bold-3.ttf");
    font-weight: 700;
}

@font-face {
    font-family: "im-roboto";
    src: url("ROBOTO/Roboto-Regular-14.ttf");
    font-weight: 400;
}

@font-face {
    font-family: "DIN";
    src: url("D-DIN/D-DIN-Bold.ttf");
}
