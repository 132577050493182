@import "../../../../../styles/mixins";
@import "../../../../../styles/variables";
@import "../../../../../styles/function";
@import "../../../../../styles/common";
@import "../../../../../styles/animation";
@import "../../../../../styles/modal";
@import "../../../../../styles/antd-moblie";
@import "../../../../../styles/map";

@mixin card {
  padding: 12px;
  background: $primary-bg-color;
  //border-bottom: 1px solid #D8E0EA;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.05);
}

.chatGroupSetting {
  position: relative;
  background: $setting-bg;
  color: $font-color-t1;

  .group-header {
    background: $primary-bg-color ;
    position: fixed;
    z-index: 99;
    display: flex;
    transition: 0.2s ease;
    width: 100vw;
    gap: 6px;
    height: 58px;
    align-items: center;
    padding: 8px 12px;
    border-bottom: 1px solid $line-bg;

    .group-header-back {
      width: 20px;
      height: 20px;
    }

    .group-header-title {
      display: flex;
      align-items: center;
      gap: 6px;

      .group-header-title-icon {
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }

      .group-header-content {
        font-size: 16px;

        .group-header-title-text {
          font-weight: 500;
          line-height: 19px;
        }

        .group-header-title-member {
          display: flex;
          align-items: center;
          color: $font-color-t2;
          line-height: 19px;
        }
      }

    }

  }

  .group-card {
    text-align: center;
    //height: 214px;
    @include card;
    position: relative;
    padding: 12px;

    .back-icon {
      width: 20px;
      height: 20px;
      position: absolute;
      left: 12px;
      top: 12px;
    }

    .image {
      width: 116px;
      height: 116px;
      border-radius: 50%;
      display: inline-block;
    }

    .group-card-title {
      font-size: 24px;
      font-family: im-roboto;
      font-weight: 400;
      color: $font-color-t2;
      margin-top: 20px;
    }

    .group-card-memeber {
      margin-top: 4px;
      color: $font-color-t3;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
    }
  }

  .group-detail {
    padding: 12px;
    margin-top: 8px;

    @include card;

    .text {
      font-size: 16px;
      color: $font-color-t2;
      font-weight: 400;
      white-space: pre-wrap;
      line-height: 19px;
      .group-detail-more {
        font-size: 16px;
        color: $base-color;
      }
    }

    .sub-text {
      color: $font-color-t3;
      font-weight: 400;
      font-size: 14px;
      margin-top: 12px;
    }
  }

  .group-setting {
    margin-top: 8px;
    @include card;
    border-bottom: 0;

    .group-setting-item {
      height: 44px;
      display: flex;
      align-items: center;

      &:not(:first-child) {
        margin-top: 12px;
      }

      .icon {
        width: 24px;
        height: 24px;
      }

      .group-setting-item-text {
        flex: 1;
        margin-left: 24px;
        font-size: 16px;
        font-weight: 400;
      }

      //.checkedBox {
      //  --height: 24
      //}
    }
  }

  .group-member {
    margin-top: 8px;
    .max-text {
      color: $font-color-t3;
      text-align: center;
      font-family: "im-roboto";
      font-size: 16px;
      margin: 18px 0;
      padding: 12px;
    }


    .group-member-value {
      color: $font-color-t3;
      font-size: 16px;
      font-weight: 400;
      position: sticky;
      background: $primary-bg-color;
      top: 58px;
      z-index: 99;

      .group-member-value_bg {
        //height: 6px;
        width: 100vw;
        background: $primary-bg-color;
        //border-top: 1px solid #D8E0EA;
      }

      .text {
        padding: 12px;
        font-size: 14px;
      }
    }

    .group-member-list {
      background: $primary-bg-color;
      padding: 8px 12px;

      .group-member-item {
        display: flex;
        align-items: center;

        .avatar {
          width: 38px;
          height: 38px;
          border-radius: 50%;
        }

        .name {
          color: $font-color-t2;
          font-size: 16px;
          font-weight: 400;
          margin-left: 16px;
        }
        &:active {
          background: $message-select-bg;
        }
        &:not(:first-child) {
          margin: 24px 0;
        }
      }
    }
  }
}

.setting-tabs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;

  .setting-tab {
    width: 63px;
    height: 32px;
    border-radius: 4px;
    background: $muted-tab-bg;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    color: $font-color-t1;
    font-size: 12px;
    font-weight: 700;
  }

  .setting-tab.active {
    background: $base-color;
    color: #F8F8F8;
  }
}
