.content {
  height: 100vh;
  width: 100vw;
  user-select: none;

  .header {
    padding: 10px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    width: 100%;

    .icon {
      width: 24px;
      height: 24px;
    }
  }

  .image__warp {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    user-select: none;
    justify-content: center;
    img {
      display: block;
      max-height: 100%;
      max-width: 100%;
      overflow-clip-margin: content-box;
      overflow: clip;
    }
  }

}
