@import "@/styles/variables";
.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 11px;
  padding-bottom: 4px;

  .empty-image {
    width: 96px;
    height: 97px;
  }

  .empty-text {
    color: $font-color-t3;
    font-size: 14px;
    margin-top: 8px;
  }
}
