// 这里写弹框样式
.home-modal {
  .adm-center-popup-wrap {
    width: 100%;
    //min-width: 100%;
    max-width: 750px;
    padding: 0 12px;
  }

  .home-modal-body {
    padding: 24px;
    display: flex;
    align-items: center;
    flex-direction: column;

    .home-modal-image {
      width: 100px;
      height: 100px;
    }

    .home-modal-tip {
      color: #000;
      text-align: center;
      font-size: 20px;
      font-weight: 700;
      letter-spacing: -0.3px;
      text-transform: capitalize;
      margin-top: 24px;
    }

    .home-modal-text {
      color: #31373D;
      text-align: justify;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.3px;
      text-transform: capitalize;
      margin-top: 12px;
    }
  }
}

.deleteModal {
  color: #000;

  .adm-center-popup-body {
    padding: 20px;
    min-width: 303px;
  }

  .title {
    color: #6D747C;
    font-size: 14px;
    font-weight: 400;
  }

  .content {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    margin-top: 30px;

    .checkedBox {
      margin-right: 4px;

      .adm-checkbox-custom-icon {
        display: flex;
        align-items: center;
      }
    }
  }

  .handler {
    margin-top: 30px;
    display: flex;
    gap: 12px;
    flex-direction: row-reverse;

    .btn {
      color: #00A884;
      font-weight: 500;
      font-size: 14px;
      padding: 10px;
      display: inline-flex;

      &:active {
        background: rgba(0, 168, 132, 0.1);
        border-radius: 51px;
      }
    }
  }

  .handlerEvery {
    flex-direction: column;
    align-items: flex-end;
  }
}

.followedContent {
  padding: 0 12px !important;

  .note {
    position: relative;

    .note-item {
      .note-item-name {
        color: $font-color-t3;
        font-size: 12px;
        font-weight: 500;
      }

      .note-item-value {
        color: $font-color-t2;
        font-size: 14px;
        font-weight: 700;
        margin-top: 4px;

        .winAmount {
          color: #F15802;
        }
      }

      &:not(:first-child) {
        margin-top: 12px;
      }
    }

    .note-close {
      position: absolute;
      width: 80px;
      height: 80px;
      top: 0;
      right: 24px;
    }
  }

  .bets {
    margin-top: 12px;
    .bets-header {
      background: $bets-head-bg;
      display: flex;
      height: 31px;
      align-items: center;
      padding: 0 8px;
      border-radius: 8px 8px 0 0;

      .cell {
        font-size: 12px;
      }
    }

    .bets-header.digit {
      height: 40px;
    }

    .bets-list {
      .bets-list-item {
        display: flex;
        height: 48px;
        padding: 0 8px;
        align-items: center;

        .game-type {
        }

        .payment-item {
          font-size: 14px;
          font-weight: 700;
          color: $font-color-t2;
        }

        .result {
          color: $font-color-t2;
        }

        &:nth-child(2n) {
          background: $bets-item-bg;
        }
      }
    }

    .cell {
      text-align: center;
      flex: 1;
      color: $font-color-t2;
      font-size: 14px;
      font-weight: 500;

      &:first-child {
        text-align: start;
      }

      &:last-child {
        text-align: end;
      }

      .winAmount {
        color: #F15802;
      }

      .loseAmount {
        color: $font-color-t1;
      }
    }
  }

}

.followedModal-handler {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .total {
    .total-price {
      font-size: 20px;
      font-weight: 700;
      color: $font-color-t1;
    }

    .total-number {
      color: $font-color-t3;
      font-size: 12px;
      font-weight: 500;
    }
  }

  .followed-button {
    display: inline-flex;
    color: $button-icon;
    font-weight: 700;
    padding: 12px 20px;
    justify-content: center;
    align-items: center;
    border-radius: 28px;
    background: $base-color;
    font-size: 14px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25), 0px 1px 0px 0px rgba(255, 255, 255, 0.20) inset;

    &:active {
      filter: brightness(0.8);
    }
  }
}

.noticeModal {
  .header {
    height: 58px;
    position: relative;

    .close-icon {
      width: 20px;
      height: 20px;
      position: absolute;
      left: 0;
      transform: translateY(-50%);
      top: 50%;
    }
    .header-title {
      color: $font-color-t2;
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      line-height: 58px;
    }
  }
  .noticeModal-content {
    display: flex;
    gap: 16px;
    .content-left {
      width: 40px;
      height: 40px;
      flex-shrink: 0;
      border-radius: 9px;
      background: $home-select-bg;
      display: flex;
      align-items: center;
      justify-content: center;
      .content-left-icon {
        display: inline-block;
        width: 24px;
        height: 24px;
      }
      img {
        width: 24px;
        height: 24px;
      }
    }
    .content-right {
      .content-right-title {
        color: $font-color-t2;
        font-size: 18px;
        font-weight: 500;
      }
      .content-right-text {
        color: $font-color-t3;
        margin-top: 8px;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        p {
          line-height: 18px;

          &:not(:first-child) {
            margin-top: 1em;
          }
        }
      }
    }
  }
}
