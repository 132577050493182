@import "~@/styles/variables.scss";

@mixin iconShadow {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);;
}

@mixin inputShadow {
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.15);
}

@mixin box {
  flex: 1 0 0;
  //border: 1px solid $line-bg;
  background: $card;
  display: flex;
  align-items: end;
  padding: 9px 8px;
  gap: 12px;
}

.container {
  //min-height: 52px;
  box-sizing: border-box;
  position: relative;
  z-index: 100;
}

.register {
  @include inputShadow();
  background: $card;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $font-color-t3;
  font-size: 18px;
  border-radius: 39px;
  height: 44px;

  span {
    margin-left: 4px;
  }
}

.inputBottom {
  display: flex;
  gap: 4px;
  align-items: end;

  .messageBox {
    flex: 1 0 0;
    background: $card;
    padding: 9px 8px;
    border-radius: 39px;
    @include inputShadow();

    .messageBox__inner {
      display: flex;
      align-items: end;
      gap: 4px;

      .chatIcon {
        width: 24px;
        height: 24px;
        display: flex;
        flex-shrink: 0;
      }

      .textAreaContainer {
        width: 100%;
        display: flex;
        flex-grow: 1;
        align-items: center;
        padding: 3px 0;
        position: relative;

        .textArea {
          outline: none;
          padding: 0;
          width: 100%;
          resize: none;
          border: none;
          font-size: 18px;
          line-height: 18px;
          height: 18px;
          box-sizing: border-box;
          color: $font-color-t2;
          background: transparent;

          &::placeholder {
            color: $font-color-t3;
          }
        }

        .textArea__placeholder {
          color: $font-color-t3;
          font-size: 18px;
          position: absolute;
          pointer-events: none;
          left: 0px;
          top: 50%;
          transform: translateY(-50%);
        }

        .textAreaHidden {
          visibility: hidden;
          position: absolute;
          left: 0;
          top: 0;
          z-index: -9999;
        }
      }

      .rightMenus {
        --gap: 16px;
      }
    }


  }

  .messageBox__hasValue {
    border-radius: 10px;
  }

  .voiceBox {
    @include box();
    @include inputShadow();
    height: 44px;
    overflow: hidden;
    align-items: center;
    color: $font-color-t3;
    font-size: 16px;
    font-weight: 400;
    padding-right: 24px;
    position: relative;
    border-radius: 30px 0 0 30px;

    .shadow {
      width: 120px;
      height: 100%;
      position: absolute;
      left: 65px;
      top: 0;
      background: $voice-shadow;
      transition: 1s;
    }

    .voiceIcon {
      width: 24px;
      height: 24px;
    }

    .voiceTime {
      z-index: 1;
      white-space: nowrap;
      width: 40px;
    }

    .cancel {
      display: flex;
      margin-left: auto;
      align-items: center;
      gap: 4px;

      .cancelIcon {
        width: 12px;
        height: 12px;
        display: inline-flex;
      }

      span {
        white-space: nowrap;
      }
    }
  }

  .chatBtn {
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 50%;
    background: $base-color;

    .chatBtn__icon {
      width: 24px;
      height: 24px;
    }
  }
}

.replyQuote {
  gap: 12px;
  padding: 6px 8px 6px 12px;
  background: $quoto-bg;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  margin-bottom: 12px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    width: 4px;
    left: 0;
    background-color: $quoto-second-color;
  }

  .replyQuote__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .replyContent__name {
      color: $quoto-second-color;
      font-weight: 500;
      font-size: 14px;
    }

    .replyClose {
      width: 12px;
      height: 12px;

    }
  }

  .replyContent {
    font-size: 14px;
    color: $font-color-t3;
    margin-top: 4px;
    font-weight: 400;

    .icon {
      width: 12px;
      height: 16px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.replyQuote__self {
  &:before {
    background-color: $quoto-base-color;
  }

  .replyQuote__header {
    .replyContent__name {
      color: $quoto-base-color;
      font-weight: 500;
      font-size: 14px;
    }
  }

}

.menus {
  border-radius: 8px;
  padding: 20px 0;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid $line-bg;
  background: $card;

  .menus__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60px;
    gap: 4px;

    .menus__item__icon {
      width: 48px;
      height: 48px;
    }

    .menus__item_text {
      color: $font-color-t3;
      font-size: 14px;
      font-weight: 400;
    }
  }
}

.quickMessage {
  //position: absolute;
  //bottom: 8px;
  display: flex;
  width: 100%;
  overflow-x: auto;
  flex-wrap: nowrap;
  gap: 8px;
  padding: 0 4px 4px;

  .quickMessage__item {
    flex-shrink: 0;
    display: flex;
    gap: 4px;
    height: 32px;
    padding: 0 8px;
    border-radius: 8px;
    background: $base-color;
    width: fit-content;
    align-items: center;

    &:active {
      filter: brightness(0.8);
    }

    .quickMessage__item__icon {
      width: 16px;
      height: 16px;
    }

    .quickMessage__item__text {
      color: $whats-white;
      font-weight: 400;
      font-size: 14px;
    }
  }
}
