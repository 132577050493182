@import "../styles/mixins";
@import "../styles/variables";
@import "../styles/function";
@import "../styles/common";
@import "../styles/animation";
@import "../styles/modal";
@import "../styles/antd-moblie";
@import "../styles/map";

:where([chat-package-id="10"]) .layout-header-home {
  --whats-bg: #0B141B;
  --whats-title: #000;
  --whats-chat-logo: #E5EEF5;
  --whats-base-color: #20C164;
  --whats-color-t1: #F9F9FB;
  --whats-color-t2: #E5EEF5;
  --whats-line: #161F26;
}

.layout {
  display: flex;
  flex-direction: column;
  height: 100%;

  .layout-header-home {
    background: $primary-bg-color;
    border-bottom: 0.5px solid $line-bg;

    .layout-header-home-content {
      color: $primary-text-color;
      height: 44px;
      font-size: 24px;
      font-weight: 700;
      display: flex;
      align-items: center;
      padding: 0 12px;

      .chat-logo {
        color: $chat-logo;
      }

      .navbar-badge {
        height: 18px;
        min-width: 18px;
        padding: 0 4px;
        font-size: 10px;
        border-radius: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $base-color;
        font-weight: 700;
        margin-left: 4px;
        color: $button-icon;
      }

      .navbar-handler {
        margin-left: auto;
        display: flex;
        align-items: center;
        gap: 16px;

        .handler-icon {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  .layout-header-chat {
    background: $whats-title;
    border-bottom: 0.5px solid $line-bg;

    .layout-header-chat-content {
      display: flex;
      align-items: center;
      height: 44px;
      padding: 10px 8px;
      gap: 4px;

      &__back {
        width: 24px;
        height: 24px;
        flex-shrink: 0;

        .navbar-backIcon {
          width: 24px;
          height: 24px;
        }
      }

      &__title {
        color: $font-color-t1;
        font-size: 16px;
        font-weight: 500;
        flex: 1;
        width: 0;

        .title {
          display: flex;
          align-items: center;
          gap: 4px;
          flex: 1;

          .title-text {
            color: $font-color-t2;
            font-size: 18px;
            line-height: 1;
            font-weight: 400;
            flex: 1;
            width: 0;
            --gap: 4px;

            .adm-space-item {
              line-height: 0;
            }

            .title-icon {
              width: 16px;
              height: 16px;
              display: inline-block;
            }

            .title-top {
              display: flex;
              flex: 1;
              --gap: 4px;
              align-items: center;
              @include ellipsis;
            }

            .title-sub-text {
              font-size: 10px;
            }
          }

          .title-image {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            flex-shrink: 0;
            background: $font-color-t4;
          }

          .title-icon {
            width: 16px;
            height: 16px;
            flex-shrink: 0;
          }
        }
      }

      &__handler {
        display: flex;
        align-items: center;
        margin-left: auto;
        gap: 16px;

        .handler-icon {
          width: 24px;
          height: 24px;
          display: flex;
        }
      }

    }
  }

  .layout-header-single {
    background: $whats-title;
    border-bottom: 0.5px solid $line-bg;

    .layout-header-single-content {
      display: flex;
      align-items: center;
      height: 44px;
      padding: 10px 8px;
      gap: 4px;

      &__back {
        width: 24px;
        height: 24px;
        flex-shrink: 0;

        .navbar-backIcon {
          width: 24px;
          height: 24px;
        }
      }

      &__title {
        color: $font-color-t1;
        font-size: 16px;
        font-weight: 500;

        .title {
          display: flex;
          align-items: center;
          gap: 4px;

          .title-image {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background: $font-color-t4;
          }

          .title-text {
            color: $font-color-t2;
            font-size: 18px;
            line-height: 1;
            font-weight: 400;
            --gap: 4px;

            .adm-space-item {
              line-height: 0;
            }

            .title-icon {
              width: 16px;
              height: 16px;
              display: inline-block;
            }

            .title-top {
              display: flex;
              --gap: 4px;
              align-items: center;
            }

            .title-sub-text {
              font-size: 10px;
            }
          }
        }
      }

      &__handler {
        display: flex;
        align-items: center;
        margin-left: auto;
        gap: 16px;

        .handler-icon {
          width: 24px;
          height: 24px;
        }
      }

    }

  }

  .layout-content {
    flex: 1;
    overflow-y: auto;
  }
}

.skeleton-message {
  padding: 8px 0;

  .skeleton-message-item {
    padding: 12px;
    height: 70px;
    display: flex;
    gap: 12px;

    .skeleton-message-item-icon {
      width: 46px;
      height: 46px;
      border-radius: 50%;
    }

    .skeleton-message-item-content {
      flex: 1;
      justify-content: space-between;
      display: flex;
      flex-direction: column;

      .adm-skeleton.adm-skeleton-paragraph-line {
        margin: 0;
        --width: 100%;
      }
    }
  }
}

:where([data-prefers-color-scheme="light"]) .layout-header-chat {
  box-shadow: 0 2px 4px 0 rgba(204, 206, 228, 0.50);
}

