@import "@/styles/variables";
.container {
  overflow: hidden;
}

.detail {
  border-radius: 8px 8px 0 0;
  background: #3497F9;
  padding: 8px;
  display: flex;
  gap: 8px;

  .pic {
    width: 32px;
    height: 32px;
    border-radius: 5px;
  }

  .detail__info {
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2px;

    .name {
      font-size: 14px;
      font-weight: 700;
    }

    .id {
      font-weight: 500;
      font-size: 10px;
    }

  }

  .detail__time {
    font-size: 10px;
    justify-content: center;
    gap: 2px;
    color: #fff;
    margin-left: auto;
    text-align: end;
    display: flex;
    flex-direction: column;

    .drawText {
      font-weight: 500;
    }

    .drawTime {
      font-weight: 700;
    }

  }
}

.forecast {
  border: 1px solid #3497F9;
  padding: 8px;
  border-radius: 0 0 8px 8px;

  .forecast__item {
    font-size: 12px;
    font-weight: 500;
    height: 20px;
    align-items: center;
    display: flex;
    justify-content: space-between;

    .forecast__item__title {
    }

    .forecast__item__value {
    }

    .forecast__item__result {
      display: flex;

      :global {
        .reflex-item {
          width: 20px;
          height: 20px;
        }
      }
    }

  }

  .line {
    height: 1px;
    background: var(--whats-card-line);
    margin: 8px 0;
  }
}
