.modal {
  background: #000;
  height: 100%;
  width: 100%;
}
.header {
  display: flex;
  padding: 10px 12px;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;

  .headerLeft {
    flex: 1;

    .headerBack {
      width: 24px;
      height: 24px;
      display: inline-flex;
    }
  }
}

.video__content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 45px;
  .video {
    background: #0a0a0a;
    object-fit: cover;
    max-height: 100%;
    max-width: 100%;
  }

}
