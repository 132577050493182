@import "../../styles/mixins";
@import "../../styles/variables";
@import "../../styles/function";
@import "../../styles/common";
@import "../../styles/animation";
@import "../../styles/modal";
@import "../../styles/antd-moblie";
@import "../../styles/map";

.home {
  background-color: $primary-bg-color;
  min-height: 100%;
  .message {
    padding: 8px 0;
    .message-item-box {
      padding: 0 12px;
      background: $primary-bg-color;
      &:active {
        background: $home-select-bg;
      }
      .message-item {
        padding: 9px 0;
        height: 72px;
        display: flex;

        .message-item-icon {
          width: 54px;
          height: 54px;
          position: relative;

          .image {
            width: 100%;
            overflow: hidden;
            height: 100%;
            border-radius: 50%;
          }

          .select-icon {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 20px;
            height: 20px;
            z-index: 10;
          }
        }

        .message-item-content {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-left: 12px;
          padding: 3px 0;
          flex: 1;

          .message-item-content-top {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .title {
              color: $font-color-t2;
              font-size: 16px;
              line-height: 27px;
              font-weight: 500;
              flex: 1;
              display: flex;
              gap: 4px;
              align-items: center;
              width: 0;
              h3 {
                font-size: 16px;
                @include ellipsis;
              }
              .home-identity__icon {
                width: 16px;
                height: 16px;
                flex-shrink: 0;
              }
            }

            .time {
              color: $base-color;
              text-align: right;
              font-size: 12px;
              font-weight: 500;
            }

            .time.time-read {
              color: $font-color-t3;
              font-weight: 400;
            }
          }

          .message-item-content-bottom {
            display: flex;
            align-items: center;

            .text {
              flex: 1;
              display: flex;
              align-items: center;
              width: 0;
              gap: 4px;

              .text-icon {
                width: 16px;
                height: 16px;
                margin-right: 4px;
                flex-shrink: 0;
              }

              .text-content {
                color: $font-color-t3;
                font-size: 15px;
                font-weight: 400;
                line-height: 27px;
                @include ellipsis;

                .text-inner-image {
                  width: 12px;
                  height: 12px;
                  display: inline-block;
                }

              }
            }

            .handler {
              display: flex;
              column-gap: 4px;
              margin-left: 8px;

              .handler-icon {
                width: 20px;
                height: 20px;
              }

              .count {
                min-width: 20px;
                border-radius: 20px;
                height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: $base-color;
                color: $button-icon;
                font-size: 10px;
                font-weight: 500;
                padding: 0 4px;

              }
            }
          }
        }
      }
    }

    .message-item-box.select {
      background: $home-select-bg;
    }
  }
}

.home-setting {
  position: fixed;
  height: 44px;
  padding-left: 12px;
  color: #31373D;
  font-weight: 500;
  background: #fff;
  display: flex;
  align-items: center;
  font-size: 20px;
  top: 0;
  z-index: 9999;
  width: 100%;
}

.passwordModal {
  margin-bottom: 100px;
  border-radius: 12px;
  overflow: hidden;
  .passwordModal-head {
    height: 44px;
    display: flex;
    padding: 13px 20px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    background: $base-color;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
  }

  .passwordModal-content {
    padding: 32px 20px;
    background: $card;

    .passwordModal-input {
      width: 116px;
      height: 23px;
      border-bottom: 2px solid $primary-border-color;
      padding-bottom: 4px;
      color: $font-color-t1;
      font-size: 16px;
      margin: 0 auto;
      display: flex;
      position: relative;
      justify-content: center;

      .input-cell {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 12px;
        position: relative;

        &-caret::before {
          content: '';
          width: 2px;
          height: 1.1em;
          margin-left: 1px;
          background: $primary-border-color;
          animation: 1s linear infinite adm-caret-blink;
          position: absolute;
          left: 0;
        }
      }

      .cell-block {
        width: 16px;
      }
    }

    .input-error {
      text-align: center;
      margin-bottom: 20px;
      margin-top: 4px;
      font-weight: 400;
      font-size: 10px;
      color: #FF4747;
    }

    .passwordModal-tips {
      font-size: 10px;
      color: $font-color-t3;
    }
  }
}
