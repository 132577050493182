@import "../../../../../styles/mixins";
@import "../../../../../styles/variables";
@import "../../../../../styles/function";
@import "../../../../../styles/common";
@import "../../../../../styles/animation";
@import "../../../../../styles/modal";
@import "../../../../../styles/antd-moblie";
@import "../../../../../styles/map";

.personalPage {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: 100% 300px;
  background-position-y: -40px;

  .float-contain {
    position: absolute;
    width: 100%;
    backdrop-filter: blur(5px);
    background-color: $person-filter-bg;
    left: 0;
    height: 100%;
    top: 0;
    padding: 44px 12px 0;
  }

  .header {
    display: flex;
    padding: 10px 12px;
    align-items: center;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;

    .header-left {
      flex: 1;
      display: flex;
      align-items: center;

      .header-back {
        width: 20px;
        height: 20px;
      }
    }

    .header-right {
      margin-left: auto;

      .header-more {
        display: flex;
        width: 24px;
        height: 24px;
      }

      .icon {
        width: 24px;
        height: 24px;
      }
    }
  }

  .user {
    height: 78px;
    width: 100%;
    display: flex;
    align-items: center;

    .user-avatar {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      .avatar-image {
        width: 72px;
        height: 72px;
        border-radius: 50%;
      }

      .vip-image {
        width: 47px;
        height: 18px;
        position: absolute;
        bottom: -4px;
      }
    }

    .user-message {
      .name {
        color: $font-color-t1;
        font-size: 16px;
        font-weight: 700;
      }

      .userId {
        color: $font-color-t3;
        font-size: 12px;
        font-weight: 500;
        margin-top: 4px;
      }
    }

    .user-heart {
      display: flex;
      padding: 8px 12px 8px 4px;
      gap: 4px;
      align-items: center;
      border-radius: 49px;
      height: 32px;
      border: 1px solid $line-bg;
      background: $heart-bg;

      .heart-image {
        width: 32px;
        height: 32px;
        margin-right: -8px;

      }

      .num {
        margin-left: 4px;
        line-height: 32px;
        color: #31373D;
        font-weight: 500;
      }
    }

    .user-muted {
      border-radius: 49px;
      padding: 8px 12px;
      border: 1px solid $line-bg;
      background: $heart-bg;

      .muted-icon {
        width: 16px;
        height: 16px;
      }
    }

    .user-muted.active {
      background: #FB5A6A;
    }
  }

  .card {
    border-radius: 8px;
    padding: 12px;
    background: $card;

    .card-title {
      font-size: 12px;
      font-weight: 700;
      color: $font-color-t1;
      margin-bottom: 12px;
    }
  }

  .statistics-content {
    display: flex;
    padding-bottom: 4px;

    .statistics-item {
      width: 33.33%;

      .statistics-item-title {
        display: flex;
        align-items: center;

        .statistics-item-icon {
          width: 14px;
          height: 14px;
          margin-right: 2px;
        }

        .text {
          color: #5F6975;
          font-weight: 500;
          font-size: 12px;

        }
      }

      &:not(:last-child) {
        border-right: 1px solid #D8E0EA;
      }

      .statistics-item-num {
        color: $font-color-t1;
        font-size: 14px;
        font-weight: 700;
        margin-top: 9px;
      }
    }
  }

  .favorite-game {
    .favorite-game-item {
      display: flex;
      align-items: center;
      padding: 12px 0;

      &:active {
        background: $message-select-bg;
      }

      .favorite-game-item-left {
        display: flex;
        flex: 1;
        //width: 224px;
        align-items: center;

        .image {
          width: 72px;
          height: 48px;
          border-radius: 4px;
          flex-shrink: 0;
        }

        .title {
          margin-left: 12px;
          font-size: 12px;
          flex: 1;
          font-weight: 700;
          color: $font-color-t2;
        }
      }

      .prize {
        text-align: end;
        width: 100px;

        .text {
          color: $font-color-t3;
          font-size: 12px;
          font-weight: 500;
        }

        .price {
          color: $secondary-color;
          font-size: 14px;
          font-weight: 700;
          margin-top: 2px;
        }
      }

      &:not(:last-child) {
        border-bottom: 1px solid $line-bg;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }

  }

  .bigAvatar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    gap: 40px;
    width: 100%;

    .bigAvatar-close {
      width: 32px;
      height: 32px;
      background: rgba(0, 0, 0, 1);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 16px;
        height: 16px;
      }
    }

    .bigAvatar-pic {
      width: 351px;
      height: 351px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .bigAvatar-like {
      display: flex;
      padding: 8px 12px 8px 6px;
      gap: 4px;
      height: 32px;
      background: #fff;
      align-items: center;
      border-radius: 49px;

      .bigAvatar-like-image {
        width: 32px;
        height: 32px;
        margin-right: -8px;
      }

      .num {
        font-size: 12px;
        color: #5F6975;
        font-weight: 500;
      }
    }
  }
}
.popover-menu-content {
  padding: 8px 0;
  .menu-item {
    height: 46px;
    display: flex;
    align-items: center;
    padding: 8px 16px;
    color: #17181C;
    gap: 8px;
    font-size: 14px;
    font-weight: 500;
    &:active {
      background: rgba(223, 224, 226, 1);
    }
    .icon {
      width: 24px;
      height: 24px;
    }
  }
}
