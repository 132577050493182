@import "@/styles/variables";

.container {
  background: $predict-bg;
  padding: 8px;
}

.line {
  height: 1px;
  background: $predict-line;
  margin: 8px 0;
}

.detail {
  display: flex;
  gap: 8px;
  color: $font-color-t3;

  .pic {
    width: 40px;
    height: 40px;
    border-radius: 6px;
  }

  .detail__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;

    .name {
      font-size: 14px;
      color: $font-color-t1;
      font-weight: 700;
    }

    .id {
      font-weight: 500;
      font-size: 10px;
    }

  }

  .detail__time {
    font-size: 10px;
    justify-content: center;
    gap: 4px;
    margin-left: auto;
    display: flex;
    flex-direction: column;

    .drawText {
      font-weight: 500;
      text-align: end;
    }

    .drawTime {
      font-weight: 700;
    }

  }

}

.forecast {
  .result {
    display: flex;
    color: $font-color-t2;
    font-size: 14px;
    font-weight: 500;
    align-items: center;

    .result_value {
      display: flex;
      margin-left: auto;
    }
  }

  .select_space {
    margin-top: 12px;
    margin-bottom: 8px;
    --gap: 8px;
  }
}

.analyze {
  display: flex;
  color: $font-color-t3;
  font-size: 12px;
  font-weight: 500;
  justify-content: space-between;

  .analyze_item {
    .analyze_item_label {
      color: $font-color-t3;
      font-family: Inter;
      font-size: 12px;
      font-weight: 500;

    }

    .analyze_item_value {
      color: $font-color-t1;
      font-family: DIN;
      font-size: 16px;
      font-weight: 700;
      margin-top: 4px;
      .hit {
        color: $secondary-color;
      }
    }

  }
}

.bet {
  margin-top: 12px;
  background: $predict-bg;
  padding: 12px 8px;

  .line {
    height: 1px;
    background: $predict-line;
    margin: 12px 0;
  }

  .bet_item {
    color: $font-color-t2;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;

    .bet_item_num {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .bet_item_num_input {
        display: flex;
        gap: 2px;

        .tab {
          border-radius: 4px;
          border: 1px solid rgba(0, 0, 0, 0.04);
          background: $predict-btn;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 8px 10px;
          color: $font-color-t1;
          font-size: 14px;
          font-weight: 700;

          .icon {
            width: 12px;
            height: 12px;
            display: inline-block;
          }
        }

        .disable {
          opacity: 0.6;
        }

        .tab.tabValue {
          width: 78px;
          background: $predict-input-bg;
          //width: 56px;
          :global(.adm-input-element) {
            text-align: center;
            color: $font-color-t1;
            font-weight: 700;
          }
        }
      }
    }

    .bet_item_value {
      margin-top: 8px;
      display: flex;
      gap: 4px;

      .bet_item_value_select {
        display: flex;
        height: 28px;
        flex: 1 1 0;
        justify-content: center;
        align-items: center;
        color: $font-color-t2;
        font-size: 12px;
        font-weight: 700;
        border-radius: 4px;
        border: 1px solid rgba(255, 255, 255, 0.10);
        background: $predict-select-bet;
      }

      .bet_item_value_select_checked {
        background: $predict-select-bet-checked;
        color: #FFF;
      }
    }
  }
}

.table_container {
  margin-top: 12px;
  width: 100%;
  overflow-x: auto;


  .table {
    border-spacing: 0;
    font-size: 12px;
    width: max-content;
    min-width: 100%;
    font-weight: 500;
    color: $font-color-t2;
    table-layout: auto;
    position: relative;

    thead {
      background: $predict-table-thead;
    }

    tr {
      &:nth-child(even) {
        background: $predict-table-even;
      }
    }

    .issue {
      text-align: left;
      width: 115px;
      .avatar {
        width: 24px;
        border-radius: 50%;
        height: 24px;
      }
    }
    .price {
      font-size: 14px;
      font-weight: 700;
      color: $font-color-t2;
      font-family: DIN;
    }
    td {
      text-align: center;
      padding: 5px 8px;
    }

    tbody {
      tr {
        &:active {
          background: $message-select-bg;
        }
      }
    }

    .status {
      //text-align: end;

      .hit {
        color: $base-color;
      }
    }

    .cell {
      font-size: 12px;
      font-weight: 500;
      width: 96px;
    }

    .arrow {
      width: 14px;
      height: 14px;
    }
  }
}

