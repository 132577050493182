@import "~@/styles/variables.scss";

.container {
  //position: fixed;
  --border-radius: 30px;
  --header-height: 24px;
  z-index: 900;
  bottom: 0;
  left: 0;
  width: 100vw;
  display: flex;
  flex-direction: column;
  touch-action: none;
  background: var(--adm-color-background);
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
  overflow: hidden;

  .mask {
    display: block;
    width: 100%;
    position: absolute;
    height: 100vh;
    left: 0;
    z-index: 999;
    top: -100vh;
  }

  .header {
    user-select: none;
    height: var(--header-height);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: grab;

    .header__bar {
      width: 33px;
      height: 4px;
      background: $font-color-t3;
      border-radius: 17px;
    }
  }

  .content {
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }
}
