@import "../../styles/mixins";
@import "../../styles/variables";
@import "../../styles/function";
@import "../../styles/common";
@import "../../styles/animation";
@import "../../styles/modal";
@import "../../styles/antd-moblie";
@import "../../styles/map";

.chat-container {
  min-height: 100%;
  background-color: $primary-bg-color;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-image: url("~@/assets/bg.png");
  background-size: cover;
  position: relative;


  // 通告样式
  .noticeBar-conatiner {
    //padding: 8px 12px;
    //height: 40px;
    //position: absolute;
    width: 100%;
    z-index: 100;

    .noticeBar {
      font-size: 12px;
      color: $notice-text-color;
      font-weight: 500;
      background: $notice-bg;
      border: none;
      position: relative;

      &:after {
        content: "";
        width: 80px;
        height: 30px;
        position: absolute;
        right: 0px;
        top: 50%;
        transform: translateY(-50%);
        background: $notice-shadow;
        z-index: 10;
      }

      .noticeBar-left {
        display: flex;
        align-items: center;
        gap: 4px;

        .icon_notice {
          width: 20px;
          height: 20px;
        }
      }

      .noticeBar-content {
        font-size: 12px;
        font-weight: 500;
      }

      .noticeBar-right {
        width: 14px;
        height: 14px;
      }
    }
  }

  // 历史记录
  .win-history {
    backdrop-filter: blur(0.5px);
    padding: 5px 12px;
    width: 262px;
    position: absolute;
    z-index: 999;
    top: 0;
    pointer-events: none;

    .win-history-list {
      .win-history-item {
        color: $whats-white;
        padding: 5px 12px;
        background: linear-gradient(90deg, rgba(255, 66, 53, 0.72) 0%, rgba(255, 146, 83, 0.90) 100%);
        //box-shadow: 0px 2px 4px 0px rgba(204, 206, 228, 0.50);
        margin: 4px 0;
        font-size: 12px;
        font-weight: 500;
        height: 24px;
        @include ellipsis();

        .price {
          color: #10FC28;
          font-weight: 700;
          font-family: "im-roboto";
        }
      }
    }
  }

  // 聊天内容
  .chat-main-container {
    flex: 1;
    position: relative;
    scroll-behavior: smooth;
    display: flex;
    flex-direction: column;

    .chat-main-inner {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      .loading {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $base-color;
      }

      .chat-row {
        padding-top: 8px;
        content-visibility: auto;

        .chat-row-container {
          position: relative;
          padding: 0 4px 0 8px;

          .chat-row-inner {
            display: flex;
            gap: 10px;

            .message-icon {
              display: flex;
              align-items: center;

              img {
                width: 16px;
                height: 16px;
              }
            }


            .chat-avatar {
              width: 28px;
              height: 28px;
              border-radius: 50%;
              flex-shrink: 0;
            }

            .message-layout {
              display: flex;
              align-items: center;
              gap: 8px;
              position: relative;
              max-width: 279px;

              .message {
                border-radius: 0 12px 12px;
                //padding: 6px 10px 4px;
                flex: 1;
                max-width: 279px;
                position: relative;
                display: flex;
                flex-direction: column;
                box-shadow: 0px 0.5px 0px 0px rgba(0, 0, 0, 0.12);
                background: $bubble-other;

                .message-nickname {
                  align-items: center;
                  display: flex;
                  margin-bottom: 4px;
                  gap: 4px;

                  .name {
                    color: $base-color;
                    font-size: 14px;
                    font-weight: 500;
                  }

                  .message-nickname-support {
                    margin-left: auto;
                    color: #FF364C;
                    font-size: 10px;
                    display: flex;
                    align-items: end;
                    gap: 2px;

                    .message-nickname-support-icon {
                      width: 16px;
                      height: 16px;
                    }
                  }
                }

                // 这里写信息样式
                .message-content {
                  position: relative;
                  word-break: break-word;
                  font-size: 16px;
                  //line-height: 20px;
                  color: $font-color-t2;
                  // 引用内容样式
                  .quote-message {
                    padding: 6px 6px 8px 10px;
                    margin-bottom: 4px;
                    position: relative;
                    border-radius: 4px;
                    overflow: hidden;
                    background-color: $quoto-info-second-color;

                    &:before {
                      content: "";
                      height: 100%;
                      position: absolute;
                      left: 0;
                      top: 0;
                      width: 4px;
                      background: $quoto-second-color;
                    }

                    .quote-message-name {
                      display: flex;
                      align-items: start;
                      justify-content: space-between;
                      color: $quoto-second-color;
                      font-weight: 500;
                      font-size: 14px;
                      line-height: 17px;
                    }

                    .quote-message-content {
                      color: $font-color-t3;
                      font-size: 14px;
                      line-height: 16px;
                      margin-top: 2px;
                      font-weight: 400;

                      .chat-card {
                        min-width: 254px;
                      }

                      .default-message-content {
                        display: flex;
                        align-items: center;
                        gap: 4px;

                        .icon {
                          width: 14px;
                          height: 14px;
                        }
                      }
                    }

                  }

                  .quote-message-quoteText {
                    padding: 0 6px;
                  }

                  .quote-message.isSelf {
                    &:before {
                      background: $quoto-base-color;
                    }

                    .quote-message-name {
                      color: $quoto-base-color;
                    }
                  }

                  // 图片样式
                  .image-message {
                    .image-message-img {
                      width: 100%;
                      height: 100%;
                      //max-height: 400px;
                      border-radius: 12px;
                      overflow: hidden;
                    }
                  }

                  .video-message {
                    position: relative;

                    .video-message-snap {
                      border-radius: 8px;
                      overflow: hidden;

                      video {
                        &::-webkit-media-controls-start-playback-button {
                          display: none;
                          -webkit-appearance: none;
                        }

                        &::-webkit-media-controls-panel {
                          display: none;
                          -webkit-appearance: none;
                        }

                        &::-webkit-media-controls-play-button {
                          display: none;
                          -webkit-appearance: none;
                        }

                      }

                    }

                    .video-message-shadow {
                      height: 28px;
                      width: 100%;
                      position: absolute;
                      padding: 0 8px;
                      bottom: 0px;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      z-index: 1;
                      border-radius: 8px;
                      //pointer-events: none;
                      background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.40) 100%);

                      .video-message-duration {
                        display: flex;
                        align-items: center;
                        gap: 2px;
                        font-size: 14px;
                        font-weight: 500;
                        color: #fff;

                        .video-message-icon {
                          width: 16px;
                          display: inline-block;
                          height: 16px;
                        }
                      }


                      .video-message-time {
                        color: #fff;
                        font-size: 12px;
                        font-weight: 400;
                      }

                      .message-group-state {
                        .video-message-time {
                          display: flex;
                          align-items: center;
                          gap: 4px;
                        }
                      }
                    }
                  }

                }

                .card-arrow {
                  position: absolute;
                  display: inline-flex;
                  top: 0;
                  width: 10px;
                  height: 12px;
                  left: -7px;
                }
              }

              .message-layout__quote {
                padding: 6px 4px 4px;
              }

              .message-layout__voice {
                width: 315px;
                padding: 6px 10px 4px;
              }
              .message-layout__betCard {
                padding: 6px 10px;
              }

              .message-layout__card {
                width: 283px;
                padding: 6px 4px 4px;

                .message-nickname {
                  padding: 0 6px;
                }
              }

              .message-layout__image {
                padding: 6px 4px 4px;

                .image-message {
                  .image-message-img {
                    width: 100%;
                    height: 100%;
                    max-height: 400px;
                  }
                }

                .message-nickname {
                  padding: 0 6px;
                }
              }

              .message-layout__text {
                padding: 6px 8px;
                min-height: 36px;
                white-space: pre-line;
                display: flex;
                flex-direction: column;
                justify-content: end;
              }

              .message-layout__video {
                padding: 4px;
              }

              .message-layout__customCard {
                padding: 6px 10px;

                .customCard {
                  width: 256px;

                  .customCard-info {
                    display: flex;
                    align-items: center;
                    gap: 16px;
                    margin-bottom: 6px;

                    .customCard-avatar {
                      width: 50px;
                      height: 50px;
                      border-radius: 50%;

                    }

                    .customCard-nickname {
                      color: $font-color-t3;
                      font-size: 14px;
                      position: relative;
                      flex: 1;
                      font-weight: 500;
                    }
                  }

                  .customCard-chat {
                    border-top: 1px solid $line-bg;
                    padding: 12px 48px;
                    color: $base-color;
                    font-size: 16px;
                    text-align: center;
                    font-weight: 500;

                    &:active {
                      background: $message-select-bg;
                    }
                  }
                }
              }
            }

            .message-status {
              display: flex;
              align-items: end;
            }

            .message-nickname-me {
              flex-direction: row-reverse;

              .name {
                margin-right: 8px;
              }
            }
          }

        }

        .chat-row-container.self {
          .chat-row-inner {
            flex-direction: row-reverse;
            display: flex;
            gap: 8px;
            padding-right: 10px;
          }

          .message-layout {
            flex-direction: row-reverse;
            max-width: 315px;

            .message {
              border-radius: 12px 0 12px 12px;
              position: relative;
              background: $bubble-mine;
              max-width: 315px;

              .card-arrow {
                position: absolute;
                display: inline-flex;
                width: 10px;
                height: 12px;
                top: 0px;
                left: initial;
                right: -9px;
              }

              .message-content {
                .quote-message {
                  background-color: $quoto-info-base-color;
                }
              }

            }

            .message-card {
              background: #fff;
              padding: 0;
            }

            .voice-message {
              flex-direction: row;

              .voice-avatar {
                top: 0;

                .voice-avatar-icon {
                  right: 0;
                  left: initial;
                }
              }

            }

            .message-layout__card {
              border-radius: 12px 0 12px 12px;
            }

            .message-layout__quote {
              padding: 4px 4px 4px;
            }

            .message-layout__voice {
              width: 315px;
              height: 64px;
              padding: 0px 10px;

              .voice-avatar {
                margin: 7px 0;
              }

              .voice-message-content {
                position: relative;
                display: flex;
                margin-top: 10.5px;
                margin-left: 0;
                flex-direction: column;

                .voice-message-content-during {
                  position: absolute;
                  width: 100%;
                  bottom: 4px;
                }
              }

            }

            .message-layout__card {
              width: 283px;
              padding: 4px 4px 4px;

              .message-nickname {
                padding: 0 6px;
              }
            }

            .message-layout__image {
              padding: 4px 4px 4px;

              .message-nickname {
                padding: 0 6px;
              }
            }

            .message-layout__text {
              padding: 6px 8px;
            }
          }
        }

        .chat-row-container.selected {
          position: relative;

          &:before {
            content: "";
            left: 0;
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            background: $message-select-bg;
            z-index: 1;
          }
        }

        .chat-row-container.admin, .chat-row-container.admin.self {
          .message {
            //border: 1px solid $admin-bubble-color !important;
            //background: $admin-bubble-bg !important;

            .message-content {
              color: $admin-bubble-color !important;
            }

            .luck-inner {
              border-width: 0;
            }
          }

          .message-card {
            border: none !important;
          }
        }
      }

      .chat-row.isMain {
        padding: 2px 0 0;

        .chat-row-container {
          .message-layout {
            .message {
              border-radius: 12px;
            }
          }

        }

        .chat-row-container.self {
          .message-layout__card {
            border-radius: 8px;
          }
        }

        .card-arrow {
          display: none !important;
        }
      }
    }


    .chat-to-bottom {
      position: absolute;
      bottom: 0;
      right: 8px;
      width: 32px;
      height: 32px;
    }

    .newMessageCount {
      //background: $base-color;
      border-radius: 50%;
      //padding: 8px 12px;
      height: 32px;
      width: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 12px;
      font-size: 14px;
      color: #fff;
      font-weight: 700;
      right: 8px;
      z-index: 100;
      box-shadow: 0px 4px 12px 0px rgba(21, 0, 48, 0.20);
    }
  }

  .chat-input-dismissedInput {
    display: flex;
    height: 52px;
    padding: 4px 5px;
    justify-content: center;
    align-items: center;
    background: #fff;
    color: #86939F;
    font-size: 18px;
  }

  // 表情框
  .chat-expression {
    --border-radius: 0;
    --header-height: 24px;
    --adm-color-background: var(--expression-bg);

    .chat-expression-handler {
      padding: 0 16px;
      padding-bottom: 8px;
      display: flex;
      justify-content: space-between;

      .chat-expression-search {
        width: 24px;
        height: 24px;
      }

      .chat-expression-switch {
        width: 128px;
        height: 28px;
        border: 1px solid #E0DFE4;
        border-radius: 14px;
        display: flex;
        overflow: hidden;

        .switch-item {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          background: $expression-bg;

          .switch-item-icon {
            width: 24px;
            height: 24px;
          }

          &:first-child {
            border-right: 1px solid #E0DFE4;
          }
        }

        .switch-item.active {
          background: $expression-active;
        }
      }

      .chat-expression-delete {
        width: 24px;
        height: 24px;
      }
    }

    .content {
      height: 100%;

      .chat-expression-emoji {
        padding: 0 16px;

        .emoji-item {
          width: 28px;
          height: 28px;
          font-size: 28px;
        }
      }

      .chat-expression-gif {
        height: 100%;

        .hm_waterfall-container {
          padding: 0 5px 5px;

          .hm_waterfall-item {
            border-radius: 4px;
          }
        }

        .gif-item {
          border: 1px solid rgba(0, 0, 0, 0.05);
          width: 100%;
          min-height: 40px;
          content: content();
        }
      }
    }

    .adm-floating-panel-bar {
      width: px2vw(33px);
      height: px2vw(4px);
      flex-shrink: 0;
      border-radius: 17px;
      background: #88969F;
    }

  }

  // 分享的卡片
  .chat-card {
    border-radius: 4px;
    $card-line: rgba(255, 255, 255, 0.1);

    .card-message {
      //max-width: 267px;
      overflow: hidden;

      .card-top {
        display: flex;
        align-items: center;
        border-radius: 8px 8px 0 0;
        padding: 8px;
        gap: 8px;
        background: $base-color;

        .game-icon {
          width: 32px;
          height: 32px;
          border-radius: 4px;
          flex-shrink: 0;
        }

        .card-title {
          flex: 1;
          font-weight: 700;
          color: #fff;

          @include ellipsis();

          .title {
            @include ellipsis();
            font-size: 14px;
            line-height: 17px;
          }

          .sub-title {
            font-size: 10px;
            margin-top: 2px;
            font-weight: 500;
          }
        }

        .remind-time {
          color: #fff;
          font-size: 12px;
          font-weight: 400;
        }

      }

      .card-inner {
        padding: 8px;
        border: 1px solid $primary-border-color;
        border-radius: 0 0 8px 8px;
        border-top-width: 0;
        background: $share-bg;

        .payment {
          font-size: 12px;
          display: flex;

          .payment-text {
            color: $font-color-t1;
            font-weight: 700;
          }

          .payment-right {
            margin-left: auto;
            display: flex;
            gap: 4px;

            .text {
              color: $font-color-t2;
              font-weight: 500;
            }

            .price {
              color: $font-color-t1;
              font-weight: 700;
              font-family: DIN;
            }
          }
        }

        .content-wapper {
          position: relative;
          margin-top: 8px;
        }

        .line {
          width: 100%;
          height: 1px;
          background: var(--whats-card-line);
          margin: 8px 0;
        }
      }


      .content-wapper.shadow {
        &::after {
          content: "";
          background: linear-gradient(270deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
          width: 50%;
          height: 100%;
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          z-index: 10;
          pointer-events: none;
        }
      }

      .content {
        overflow-x: auto;
        @include removeScrollBar;

        .color-game {
          gap: 12px;
          display: flex;

          .color-game-item {
            display: flex;
            flex-shrink: 0;
            align-items: center;
            gap: 8px;

            .ball-icon {
              width: 24px;
              height: 24px;
              background-size: cover;
              display: flex;
              align-items: center;
              justify-content: center;
              font-weight: 700;
              font-size: 10px;
              color: #fff;
              margin-right: 8px;
            }

            .price {
              color: #31373D;
              font-size: 14px;
              font-weight: 700;
              font-family: "im-roboto", im-roboto, Inter, serif;
            }
          }
        }

        .dice-game {
          gap: 8px;
          display: flex;
          align-items: center;

          .dice-game-item {
            display: flex;
            flex-shrink: 0;
            align-items: center;
            gap: 8px;

            .price {
              color: #31373D;
              font-size: 14px;
              font-weight: 700;
              font-family: "im-roboto", im-roboto, Inter, serif;
            }
          }
        }
      }

    }

    .followed-self, .followed-other {
      margin-top: 8px;
      display: flex;
      align-items: center;
    }

    .followed-self {
      .followed-left {
        color: $font-color-t3;
        font-size: 12px;
        font-weight: 500;
        flex: 1;
      }
    }

    .followed-other {
      justify-content: space-between;

      .followed-button {
        display: inline-flex;
        color: $button-icon;
        font-weight: 700;
        height: 28px;
        font-size: 12px;
        padding: 8px 12px;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        background: $base-color;

        &:active {
          filter: brightness(0.8);
        }
      }
    }

    .card-time {
      margin-top: 6px;
      text-align: end;
      color: #5F6975;
    }
  }

  .chat-card.quote {
    padding: 0;
  }

  // luck卡片
  .luck-message {
    align-self: stretch;
    border-radius: 4px;
    min-width: 254px;

    .luck-message-title {
      display: flex;
      align-items: center;
      color: #fff;
      font-size: 14px;
      font-weight: 700;
      padding: 8px;
      background: $secondary-border-color;
      border: 1px solid $secondary-border-color;
      border-radius: 8px 8px 0 0;
      border-bottom-width: 0;


      .icon {
        width: 34px;
        height: 25px;
        margin-right: 4px;
      }
    }

    .line {
      width: 100%;
      height: 1px;
      background: var(--whats-card-line);
      margin: 8px 0;
    }

    .luck-inner {
      padding: 8px;
      border: 1px solid $secondary-border-color;
      border-top-width: 0;
      background: $card;
      border-radius: 0 0 8px 8px;

      .luck-message-box {
        display: flex;

        .luck-message-box-icon {
          width: 32px;
          height: 32px;
          margin-right: 8px;
          border-radius: 4px;
        }

        .luck-content {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          flex: 1;
          padding: 2px 0;
          min-width: 120px;

          .luck-content-title {
            color: $font-color-t2;
            font-size: 12px;
            font-weight: 700;
            text-transform: capitalize;
            flex: 1;
            @include ellipsis()
          }

          .luck-content-price {
            color: $font-color-t2;
            font-size: 10px;
            font-weight: 700;
            font-family: 'DIN';
          }
        }

        .luck-won {
          margin-left: auto;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          padding: 2px 0;

          .won-text {
            color: $font-color-t3;
            font-size: 10px;
            font-weight: 500;
            text-align: end;
          }

          .won-price {
            color: #F15802;
            font-size: 14px;
            font-weight: 700;
            font-family: DIN;
            max-width: 58px;
            @include ellipsis();
          }
        }
      }

      .luck-message-btn {
        display: flex;
        margin-top: 8px;
        justify-content: space-between;

        .btn {
          display: flex;
          height: 28px;
          padding: 8px 12px;
          justify-content: center;
          align-items: center;
          gap: 4px;
          border-radius: 4px;
          background: $secondary-border-color;
          //box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25), 0px 1px 0px 0px rgba(255, 255, 255, 0.20) inset;

          span {
            color: $button-icon;
            font-size: 12px;
            font-weight: 700;
          }

          &:active {
            filter: brightness(0.8);
          }
        }
      }
    }
  }

  // 语音类型样式
  .voice-message {
    width: 100%;
    display: flex;
    gap: 16px;
    flex-direction: row-reverse;
    margin-top: 4px;

    .voice-avatar {
      display: flex;
      top: -4px;
      flex-shrink: 0;
      position: relative;
      overflow: visible;

      .voice-avatar-image {
        border-radius: 50%;
        width: 50px;
        height: 50px;
      }

      .voice-avatar-icon {
        position: absolute;
        left: 0;
        bottom: 0px;
        width: 14px;
        height: 21px;
      }
    }

    .voice-message-content {
      padding-top: 10px;
      margin-left: 8px;
      flex: 1;

      .voice-message-top {
        display: flex;
        align-items: center;
        gap: 14px;

        .voice-message-content-handler {
          width: 22px;
          height: 22px;
        }

        .message-content-wave {
          flex: 1;
          height: 20px;
          position: relative;
          z-index: 99;
          max-height: 20px;
        }
      }

      .voice-message-content-during {
        color: $font-color-t3;
        font-size: 12px;
        padding-left: 36px;
        margin-top: 4px;
        font-weight: 400;
        display: flex;
        justify-content: space-between;
        align-items: end;
      }
    }

  }

  // 底部音频录制
  .voice-box {
    padding: 12px;
    background: #fff;

    .voice-box-show {
      height: 40px;
      display: flex;
      align-items: center;

      .progress {
        font-size: 20px;
        font-weight: 500;
        margin-right: 8px;
      }

      .wave {
        height: 100%;
        flex: 1;
      }
    }

    .voice-box-handler {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 12px;

      .handler-item {
        .icon {
          width: 38px;
          height: 38px;
        }
      }

      .sendBtn {
        background: #00A884;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 46px;
        height: 46px;

        .icon {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}

.role-tag {
  border-radius: 4px;
  padding: 8px;
  font-size: 10px;
  font-weight: 400;
  width: 78px;
  box-sizing: border-box;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.role-tag-super {
  color: $tag-super-text;
  background: $tag-super-bg;
}

.role-tag-group {
  color: $tag-group-text;
  background: $tag-group-bg;
}

.message-state {
  color: $font-color-t3;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  align-items: end;

  span {
    line-height: 16px;
  }

  .message-icon-sending, .message-icon-read {
    width: 16px;
    height: 16px;
    margin-left: 2px;
    display: inline-block;
    align-items: end;
  }


  .message-status-success {
    display: flex;
    align-items: center;
    gap: 2px;
  }
}

.message-state.message-group-state {
  flex-direction: row-reverse;
}

.message-group-state {
  display: flex;
  justify-content: space-between;
}

.message-state__bottom {
  margin-top: 4px;
  text-align: end;
  justify-content: end;
  padding: 0 10px;
}

.message-state_float {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
  text-align: end;
  position: absolute;
  left: 0;
  width: 100%;
  height: 28px;
  bottom: 0px;
  padding: 10px 10px 4px;
  display: flex;
  justify-content: end;
  color: #fff;
  z-index: 10;
}

.message-state_float.message-group-state {
  justify-content: space-between;
}

.message-state__right {
  margin-left: 8px;
  display: inline-flex;
  gap: 2px;
  position: relative;
  top: .375rem;
  float: right;
  bottom: auto !important;
  justify-content: end;
  user-select: none;
  line-height: 1.35;

  span {
  }
}

.message-state__right.message-group-state {
  display: flex;
  float: none;
  margin-left: 0;
  justify-content: space-between;
}

.system-box {
  display: flex;
  justify-content: center;
  align-items: center;
  //padding: 8px 12px;
  padding-top: 8px;

  .system-text {
    color: $font-color-t3;
    line-height: 1.2;
    font-size: 12px;
    padding: 4px 8px;
    background: $system-message-bg;
    box-shadow: $system-message-shadow;
    border-radius: 5px;
    font-weight: 500;
  }
}

:where([data-prefers-color-scheme="light"]) .chat-container {
  background-image: url("~@/assets/bg-light.png");
  background-color: var(--whats-chat-bg);
}

.message-loading {
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;

  .message-loading-ball {
    width: 48px;
    background: rgba(0, 0, 0, 0.40);
    height: 48px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    display: flex;
    align-items: center;
    pointer-events: none;
    justify-content: center;

    .message-loading-ball__icon {
      width: 32px;
      height: 32px;
    }
  }

  .message-loading-progress-bar {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

@each $vip-name, $vip-url in $vips-images {
  .#{$vip-name} {
    background-image: url("~#{$vip-url}");
    @if ($vip-name == "vip10") {
      width: 54px;
      height: 16px;
    } @else if ($vip-name == "vip11") {
      width: 63px;
      height: 16px;
    } @else {
      width: 42px;
      height: 16px;
    }
    background-size: cover;
  }
}
.message-layout__betCard {
  background: linear-gradient(90deg, #FDE1B8 0%, #FFF2E1 100%)!important;
  width: 279px;
}
.self {
  .message-layout__betCard {
    background: linear-gradient(90deg, #FDE1B8 100%, #FFF2E1 0%)!important;
  }
}
.message-layout__betCard:has(.wonType) {
  background: linear-gradient(90deg, #FCC8CB 0%, #FFE6E6 100%)!important;
}
.self {
  .message-layout__betCard:has(.wonType) {
    background: linear-gradient(90deg, #FCC8CB 100%, #FFE6E6 0%)!important;
  }
}
