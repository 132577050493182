:root:root {
  --adm-center-popup-min-width: 313px;
  --adm-center-popup-z-index: 2000;
  --adm-color-weak: #D8E0EA;
  --adm-color-primary: #1FA764;
  --adm-font-family: im-roboto,-apple-system, blinkmacsystemfont, 'Helvetica Neue',
  helvetica, segoe ui, arial, roboto, 'PingFang SC', 'miui',
  'Hiragino Sans GB', 'Microsoft Yahei', sans-serif;
}
html[data-prefers-color-scheme='dark'] {
  //--adm-color-primary: #3086ff;
  //--adm-color-success: #34b368;
  //--adm-color-warning: #ffa930;
  //--adm-color-danger: #ff4a58;
  //--adm-color-yellow: #ffa930;
  //--adm-color-orange: #e65a2b;
  //--adm-color-wathet: #0d2543;
  //--adm-color-text: #e6e6e6;
  //--adm-color-text-secondary: #b3b3b3;
  //--adm-color-weak: #808080;
  //--adm-color-light: #4d4d4d;
  //--adm-color-border: #2b2b2b;
  //--adm-color-box: rgba(21, 21, 21, 1);
  //--adm-color-box: #0a0a0a;
  --adm-color-background: #283138;
  --adm-color-background-body: var(--adm-color-background);
  //--adm-border-color: var(--adm-color-border);
}

// 这里修改antd的全局样式
.adm-toast-mask .adm-toast-main {
  min-width: 280px;
  max-width: 75vw;
  padding: 24px;
}

.adm-mask {
  //backdrop-filter: blur(2px);
}

.adm-ellipsis {
  line-height: 16px;
}

.adm-number-keyboard-popup.adm-popup {
 z-index: 2000;
}

.adm-error-block-description {
  color: $font-color-t2;
}
.adm-popover {
  .adm-popover-inner-content {
    --content-padding: 0;
  }
}
