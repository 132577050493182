// 全局变量
$font-size: 14px;
$list-item-active-bg: #F7F8FA;
$whats-white:var(--whats-white);
$base-color: var(--whats-base-color);
$base-text-color: $base-color;
$primary-text-color: $base-color;
$primary-bg-color: var(--whats-bg);
$primary-border-color: $base-color;
// 字体颜色相关
$font-color-t1: var(--whats-color-t1);
$font-color-t2: var(--whats-color-t2);
$font-color-t3: var(--whats-color-t3);
$font-color-t4: var(--whats-color-t4);

// 聊天相关背景
$bubble-mine: var(--whats-main-bubble);
$bubble-other: var(--whats-sub-bubble);
$button-icon: var(--whats-button-icon);
$chat-logo: var(--whats-chat-logo);
// chat页面的高亮
$message-select-bg: var(--whats-message-select-bg);
// home页面的高亮
$home-select-bg: var(--whats-active);

// 顶部标题背景色
$whats-title: var(--whats-title);
$home-selectBar-bg: var(--home-selectBar-bg);

$card: var(--whats-card);
$line-bg: var(--whats-line);

$predict-line:var(--predict-line);
$predict-bg: var(--predict-bg);
$predict-btn: var(--predict-btn);
$predict-input-bg: var(--predict-input-bg);
$predict-select-bet:  var(--predict-select-bet);
$predict-select-bet-checked: var(--predict-select-bet-checked);
$predict-table-thead: var(--predict-table-thead);
$predict-table-even: var(--predict-table-even);

// 公告栏
$notice-bg: $whats-title;
$notice-text-color: $font-color-t2;
// 顶部公告的渐变
$notice-shadow: var(--whats-notice-shadow);
// 引用框背景
$quoto-bg: var(--quoto-bg);
$quoto-base-color: var(--quoto-second-color);
$quoto-second-color: var(--quoto-base-color);
$quoto-info-base-color: var(--quoto-info-base-color);
$quoto-info-second-color: var(--quoto-info-second-color);

// 弹框样式
$modal-handler-bg:var(--modal-handler-bg);
// 群设置     "--setting-bg": "#000",
$setting-bg: var(--setting-bg);
$tag-group-bg: var(--role-tag-group-bg);
$tag-group-text: var(--role-tag-group-text);
$tag-super-bg: var(--role-tag-super-bg);
$tag-super-text: var(--role-tag-super-text);

// 聊天界面系统气泡
$system-message-bg: var(--system-message-bg);
$system-message-shadow: var(--system-message-shadow);
// 分享卡片背景
$share-bg: var(--share-bg);
$expression-active:var(--expression-active);
$expression-bg: var(--expression-bg);
// ------

$admin-bubble-color: var(--admin-bubble-color);
$admin-bubble-bg: var(--admin-bubble-bg);



// 辅助色
$secondary-border-color: var(--secondary-border-color);
$secondary-color: var(--secondary-color);

$voice-shadow: var(--voice-shadow);
$heart-bg: var(--heart-bg);


// 静音
$muted-tab-bg: var(--muted-tab-bg);
$bets-head-bg: var(--bets-head-bg);
$bets-item-bg: var(--bets-item-bg);

// person
$person-filter-bg: var(--person-filter-bg);
// 底部输入框颜色
$bottom-box-bg: var(--bottom-box-bg);


