@import "../../styles/mixins";
@import "../../styles/variables";
@import "../../styles/function";
@import "../../styles/common";
@import "../../styles/animation";
@import "../../styles/modal";
@import "../../styles/antd-moblie";
@import "../../styles/map";

.twiceModal {
  padding: 20px;
  font-size: 14px;

  .title {
    color: #6D747C;
    font-family: "im-roboto";
    font-weight: 400;
  }

  .content {
    padding: 30px 0;
    color: #000;
    font-weight: 500;
  }

  .handler {
    display: flex;
    gap: 12px;
    flex-direction: row-reverse;

    .btn {
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #00A884;

      &:active {
        background: rgba(0, 168, 132, 0.1);
        border-radius: 51px;
      }
    }
  }

}
